
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonTitle, IonIcon, IonButton, modalController } from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
import { useRoute } from "vue-router";
import { reactive, ref, onMounted } from "vue";
import { openToast } from "../services/toast";

export default {
    name: "ModalFirmaIntervento",
    props: {
        data: {
            type: String,
            required: true,
        },
        firma: {
            type: String,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonTitle,
        IonIcon,
        IonButton,
    },
    setup(props) {
        const route = useRoute();
        const interventoID = route.params.id;
        const hasSigned = ref(false);

        const canvas = ref<HTMLCanvasElement | null>(null);
        const ctx = ref<CanvasRenderingContext2D | null>(null);
        const point = reactive({ x: 0, y: 0 });
        const moving = ref(false);
        const firma_da_cancellare = ref(false);
        const reference = props.data === "tecnico" ? "tecnico" : "cliente";

        function populateCanvas() {
            if (props.firma && props.firma.length !== 0) {
                const sign = new Image();

                sign.onload = function() {
                    if (canvas.value) {
                        ctx.value.drawImage(sign, 0, 0);
                    }
                };
                sign.src = `data:image/png;base64,${props.firma}`;
            }
        }

        const closeModal = async () => {
            if (firma_da_cancellare.value) {
                await modalController.dismiss({ da_cancellare: true, tipo: reference });
            } else {
                modalController.dismiss();
            }
        };

        // Coordinate coorenti del tratto
        function getMousePos(event) {
            const canvasElement = canvas.value;
            const rect = canvasElement.getBoundingClientRect();
            let clientX, clientY;

            if (event.touches && event.touches.length > 0) {
                clientX = event.touches[0].clientX;
                clientY = event.touches[0].clientY;
            } else {
                clientX = event.clientX;
                clientY = event.clientY;
            }

            return [clientX - rect.left, clientY - rect.top];
        }

        // Inizio tratto
        const lastX = ref(0);
        const lastY = ref(0);
        function mStart(e: TouchEvent) {
            e.preventDefault();
            e.stopPropagation();

            hasSigned.value = true;
            /* const pos = { x: e.touches[0].clientX, y: e.touches[0].clientY };
            const rect = (e.target as HTMLElement).getBoundingClientRect();
            point.x = (pos.x - rect.left) * (canvas.value!.width / rect.width);
            point.y = (pos.y - rect.top) * (canvas.value!.height / rect.height);
            ctx.value.beginPath();
            ctx.value.moveTo(point.x, point.y); */
            [lastX.value, lastY.value] = getMousePos(event);
            moving.value = true;
        }

        // Continuazione tratto
        function mMove(e: TouchEvent) {
            if (moving.value) {
                e.preventDefault();
                e.stopPropagation();

                /*const pos = { x: e.touches[0].clientX, y: e.touches[0].clientY };
                const rect = (e.target as HTMLElement).getBoundingClientRect();
                const x = (pos.x - rect.left) * (canvas.value!.width / rect.width);
                const y = (pos.y - rect.top) * (canvas.value!.height / rect.height);

                ctx.value.lineTo(x, y);
                ctx.value.stroke();
                //ctx.value.moveTo(x, y);

                point.x = x;
                point.y = y;*/

                const [x, y] = getMousePos(event);

                ctx.value.beginPath();
                ctx.value.moveTo(lastX.value, lastY.value);
                ctx.value.lineTo(x, y);
                ctx.value.stroke();

                [lastX.value, lastY.value] = [x, y];
            }
        }

        function mEnd(e: TouchEvent) {
            if (moving.value) {
                e.preventDefault();
                e.stopPropagation();

                ctx.value.closePath();
                moving.value = false;
            }
        }

        function clearCanvas() {
            ctx.value.clearRect(0, 0, canvas.value!.width, canvas.value!.height);
            hasSigned.value = false;
            firma_da_cancellare.value = true;
        }

        function getCanvas() {
            return canvas.value!.toDataURL();
        }

        async function closeModalOnSubmit() {
            if (hasSigned.value) {
                const sign = getCanvas().replace("data:image/png;base64,", "");
                if (sign) {
                    await modalController.dismiss({ firma: sign, tipo: reference });
                } else {
                    await modalController.dismiss();
                }
            } else {
                openToast("Non puoi confermare senza aver firmato", "toast_danger");
            }
        }

        function confermaFirme() {
            const sign = getCanvas().replace("data:image/png;base64,", "");
            if (sign) {
                closeModalOnSubmit();
            }
        }

        function setCanvasSize() {
            const canvasElement = canvas.value;
            /* const rect = canvasElement.getBoundingClientRect();
            canvasElement.width = rect.width;
            canvasElement.height = rect.height; */
            canvasElement.width = window.innerWidth;
            canvasElement.height = window.innerHeight;
            ctx.value = canvasElement.getContext("2d");
            ctx.value.strokeStyle = "#000";
            ctx.value.lineJoin = "round";
            ctx.value.lineCap = "round";
            ctx.value.lineWidth = 3;
        }

        onMounted(() => {
            setCanvasSize();
            /* canvas.value!.width = 480;
            canvas.value!.height = 960; */
            /* canvas.value!.width = 120;
            canvas.value!.height = 600; */

            /* ctx.value = canvas.value!.getContext("2d")!;
            ctx.value.lineWidth = 2;
            ctx.value.lineCap = "round";
            ctx.value.lineJoin = "round"; */
            populateCanvas();
        });

        return {
            x: point.x,
            y: point.y,
            canvas,
            mStart,
            mMove,
            mEnd,
            clearCanvas,
            getCanvas,
            arrowBackOutline,
            closeModal,
            reference,
            confermaFirme,
        };
    },
};
